import type {
  CertificateTheme,
  ScopeThreeCategoryEnum,
} from "@s2z-platform/constants";
import type { YYYYMMDateString, YYYYMMDDDateString } from "../../common";

export class YYYYMMDDDateRange {
  start: YYYYMMDDDateString;
  end: YYYYMMDDDateString;
}

export class CustomerFootprintConfig {
  offsetFootprint: boolean;
  period: YYYYMMDDDateRange;
}

export class CustomerCertificateConfig {
  hideCost: boolean;
  // TODO: make themes type, and import to front (1 source of truth)
  theme: CertificateTheme = "standard";
  reference?: string;
}

export class CustomerIdentifierChoiceJSON {
  identifier: string;
  quantity: number;
}

class SharedCustomerConfigJSON {
  footprintConfig?: CustomerFootprintConfig;
  certificateConfig?: CustomerCertificateConfig;
}

export class MileageDetailsJSON {
  years: number;
  effectiveStartDate: YYYYMMDateString;
}

// BANANA - Move offset settings (and store info?) into customer config entity
export class EcommerceIntegrationCustomerConfigJSON {
  // not present on older subscriptions which attached the offset settings to the store
  // thanks CW
  offsetSettingsId?: number;
}

export class PresetCustomerConfigJSON extends SharedCustomerConfigJSON {
  plansConfig: CustomerIdentifierChoiceJSON[];
}
export class CustomCustomerConfigJSON extends SharedCustomerConfigJSON {
  optionsConfig: CustomerIdentifierChoiceJSON[];
}
export class UsageCustomerConfigJSON extends SharedCustomerConfigJSON {
  usageConfig: EcommerceIntegrationCustomerConfigJSON;
}
export class CarbonVehicleCustomerConfigJSON extends SharedCustomerConfigJSON {
  vehicleUuids: string[];
  mileageDetails: MileageDetailsJSON;
}
export class OfferCustomerConfigJSON extends CustomCustomerConfigJSON {
  offerConfig: { eventName: string; offerCode?: string };
}

export class FlatRateCustomerConfigJSON {}

// should match product's config
export const BASE_PRODUCT_IDENTIFIER_SCOPE_1_2 =
  "verify_footprint_scopes_one_two";
export const BASE_PRODUCT_IDENTIFIER_SCOPE_3_CAT =
  "verify_footprint_scope_three_category";

export class VerifyScopeOneAndTwoCustomerIdentifierChoiceJSON {
  identifier: typeof BASE_PRODUCT_IDENTIFIER_SCOPE_1_2;
  quantity: number;
}

export class VerifyScopeThreeCategoryCustomerIdentifierChoiceJSON {
  identifier: typeof BASE_PRODUCT_IDENTIFIER_SCOPE_3_CAT;
  quantity: number;
  category: ScopeThreeCategoryEnum;
}

export class VerifyFootprintCustomerConfigJSON {
  footprintUuid: string;

  optionsConfig: (
    | VerifyScopeOneAndTwoCustomerIdentifierChoiceJSON
    | VerifyScopeThreeCategoryCustomerIdentifierChoiceJSON
  )[];
}

export type CustomerConfigJSON =
  | PresetCustomerConfigJSON
  | CustomCustomerConfigJSON
  | UsageCustomerConfigJSON
  | CarbonVehicleCustomerConfigJSON
  | OfferCustomerConfigJSON
  | FlatRateCustomerConfigJSON
  | VerifyFootprintCustomerConfigJSON;
