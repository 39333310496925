import { mkenum } from "../utils/mkenum";
import type { AccountType } from "./user";

// Base product enums
export const ImpactProductIdentifier = mkenum({
  "business-instant-onetime": "business-instant-onetime",
  "business-vehicle-onetime": "business-vehicle-onetime",
  "business-footprint-onetime": "business-footprint-onetime",
  "business-footprint-recurring": "business-footprint-recurring",
  "business-workforce-recurring": "business-workforce-recurring",
  "business-ecommerce-recurring": "business-ecommerce-recurring",
  "business-offer-onetime": "business-offer-onetime",
  "business-off-platform-impact": "business-off-platform-impact",
  "personal-instant-onetime": "personal-instant-onetime",
  "personal-vehicle-onetime": "personal-vehicle-onetime",
  "personal-family-recurring": "personal-family-recurring",
  "personal-individual-recurring": "personal-individual-recurring",
  "personal-offer-onetime": "personal-offer-onetime",
  "personal-off-platform-impact": "personal-off-platform-impact",
});

export const ServiceProductIdentifier = mkenum({
  "business-premium-recurring": "business-premium-recurring",
  "business-verify-footprint-onetime": "business-verify-footprint-onetime",
});

// Combined product enum
export const ProductIdentifier = mkenum({
  ...ImpactProductIdentifier,
  ...ServiceProductIdentifier,
});

// non-impact based fulfillment
export const PRODUCTS_REQUIRING_PAYMENT_FULFILLMENT: ProductIdentifier[] = [
  "business-verify-footprint-onetime",
];

export type ImpactProductIdentifier =
  (typeof ImpactProductIdentifier)[keyof typeof ImpactProductIdentifier];

export type ServiceProductIdentifier =
  (typeof ServiceProductIdentifier)[keyof typeof ServiceProductIdentifier];

export type ProductIdentifier =
  (typeof ProductIdentifier)[keyof typeof ProductIdentifier];

// Get all products as arrays
const allProducts = Object.values(ProductIdentifier) as ProductIdentifier[];

/**
 * Product groups with type assertions for different categories
 */
const PRODUCT_GROUPS = {
  oneTime: allProducts.filter(
    (id): id is Extract<ProductIdentifier, `${string}-onetime`> =>
      id.includes("-onetime"),
  ),
  vehicle: allProducts.filter(
    (id): id is Extract<ProductIdentifier, `${string}-vehicle-onetime`> =>
      id.includes("-onetime"),
  ),
  presetSubscription: allProducts.filter(
    (
      id,
    ): id is Extract<
      ProductIdentifier,
      | `${string}-family-recurring`
      | `${string}-individual-recurring`
      | `${string}-workforce-recurring`
    > =>
      id.includes("-family-recurring") ||
      id.includes("-individual-recurring") ||
      id.includes("-workforce-recurring"),
  ),
  usageSubscription: allProducts.filter(
    (id): id is Extract<ProductIdentifier, `${string}-ecommerce-recurring`> =>
      id.includes("-ecommerce-recurring"),
  ),
  customSubscription: allProducts.filter(
    (id): id is Extract<ProductIdentifier, `${string}-footprint-recurring`> =>
      id.includes("-footprint-recurring"),
  ),
  offer: allProducts.filter(
    (id): id is Extract<ProductIdentifier, `${string}-offer-${string}`> =>
      id.includes("-offer-"),
  ),
  manual: allProducts.filter(
    (id): id is Extract<ProductIdentifier, `${string}-off-platform-impact`> =>
      id.includes("-off-platform-impact"),
  ),
  flatRateSubscription: allProducts.filter(
    (id): id is Extract<ProductIdentifier, `${string}-premium-recurring`> =>
      id.includes("-premium-recurring"),
  ),
  serviceOneTime: allProducts.filter(
    (
      id,
    ): id is Extract<ProductIdentifier, `${string}-verify-footprint-onetime`> =>
      id.includes("-verify-footprint-onetime"),
  ),
} as const;

// Export types and their corresponding arrays
export type OneTimeProductIdentifier =
  (typeof PRODUCT_GROUPS)["oneTime"][number];
export const oneTimeProductIdentifiers = PRODUCT_GROUPS.oneTime;
export type OneTimeImpactProductIdentifiers = Exclude<
  OneTimeProductIdentifier,
  ServiceProductIdentifier
>;

export type VehicleProductIdentifier =
  (typeof PRODUCT_GROUPS)["vehicle"][number];
export const vehicleProductIdentifiers = PRODUCT_GROUPS.vehicle;

export type PresetSubscriptionProductIdentifier =
  (typeof PRODUCT_GROUPS)["presetSubscription"][number];
export const presetSubscriptionProductIdentifiers =
  PRODUCT_GROUPS.presetSubscription;

export type UsageSubscriptionProductIdentifier =
  (typeof PRODUCT_GROUPS)["usageSubscription"][number];
export const usageSubscriptionProductIdentifiers =
  PRODUCT_GROUPS.usageSubscription;

export type CustomSubscriptionProductIdentifier =
  (typeof PRODUCT_GROUPS)["customSubscription"][number];
export const customSubscriptionProductIdentifiers =
  PRODUCT_GROUPS.customSubscription;

export type OfferProductIdentifier = (typeof PRODUCT_GROUPS)["offer"][number];
export const offerProductIdentifiers = PRODUCT_GROUPS.offer;

export type ManualProductIdentifier = (typeof PRODUCT_GROUPS)["manual"][number];
export const manualProductIdentifiers = PRODUCT_GROUPS.manual;

export type FlatRateSubscriptionProductIdentifier =
  (typeof PRODUCT_GROUPS)["flatRateSubscription"][number];

export type ServiceOneTimeProductIdentifier =
  (typeof PRODUCT_GROUPS)["serviceOneTime"][number];

export type VerifyFootprintProductIdentifier = Extract<
  ServiceProductIdentifier,
  "business-verify-footprint-onetime"
>;

// All product identifiers
export const productIdentifiers = Object.values(ProductIdentifier);

export const flatRateSubscriptionProductIdentifiers =
  PRODUCT_GROUPS.flatRateSubscription;

// Helper function for typed arrays
const createConstArray = <T extends ProductIdentifier>(
  arr: T[],
): readonly T[] => arr;

// Combine subscription products
export const subscriptionProductIdentifiers = createConstArray([
  ...PRODUCT_GROUPS.presetSubscription,
  ...PRODUCT_GROUPS.customSubscription,
  ...PRODUCT_GROUPS.usageSubscription,
  ...PRODUCT_GROUPS.flatRateSubscription,
]);

export type SubscriptionProductIdentifier =
  | PresetSubscriptionProductIdentifier
  | CustomSubscriptionProductIdentifier
  | UsageSubscriptionProductIdentifier
  | FlatRateSubscriptionProductIdentifier;

export type NonOfferProductIdentifier = Exclude<
  ProductIdentifier,
  `${string}-offer-${string}`
>;
// Combine non-offer products
export const nonOfferProductIdentifiers = createConstArray(
  productIdentifiers.filter(
    (pi): pi is NonOfferProductIdentifier => !pi.includes("offer"),
  ) as NonOfferProductIdentifier[],
);

// Type guards/helpers
export const isSubscriptionProduct = (
  id: ProductIdentifier,
): id is SubscriptionProductIdentifier =>
  subscriptionProductIdentifiers.includes(id as SubscriptionProductIdentifier);

export const isFlatRateSubscriptionProduct = (
  id: ProductIdentifier,
): id is FlatRateSubscriptionProductIdentifier =>
  flatRateSubscriptionProductIdentifiers.includes(
    id as FlatRateSubscriptionProductIdentifier,
  );

export const serviceOneTimeProductIdentifiers = PRODUCT_GROUPS.serviceOneTime;

export const isServiceOneTimeProduct = (
  id: ProductIdentifier,
): id is FlatRateSubscriptionProductIdentifier =>
  serviceOneTimeProductIdentifiers.includes(
    id as ServiceOneTimeProductIdentifier,
  );

export const isImpactProduct = (id: ProductIdentifier) =>
  id in ImpactProductIdentifier;

export const isServiceProduct = (id: ProductIdentifier) =>
  id in ServiceProductIdentifier;

export const isBusinessProduct = (
  id: ProductIdentifier,
): id is BusinessProductIdentifier => id.startsWith("business");

export const isPersonalProduct = (
  id: ProductIdentifier,
): id is PersonalProductIdentifier => id.startsWith("personal");

export const prodRequiresPaymentFulfillment = (
  id: ProductIdentifier,
): id is ProductIdentifier =>
  PRODUCTS_REQUIRING_PAYMENT_FULFILLMENT.includes(id);

/**
 * Mapping of account types to their available products
 */
export const profileTypeAllProductMapping: Record<
  AccountType,
  ProductIdentifier[]
> = {
  business: productIdentifiers.filter((id) => isBusinessProduct(id)),
  personal: productIdentifiers.filter((id) => isPersonalProduct(id)),
} as const;

export const profileTypeImpactProductMapping: Record<
  AccountType,
  ProductIdentifier[]
> = {
  business: productIdentifiers.filter(
    (id) => isBusinessProduct(id) && isImpactProduct(id),
  ),
  personal: productIdentifiers.filter(
    (id) => isPersonalProduct(id) && isImpactProduct(id),
  ),
} as const;

// Business and personal product identifier types
export type BusinessProductIdentifier = Extract<
  ProductIdentifier,
  `business-${string}`
>;
export type PersonalProductIdentifier = Extract<
  ProductIdentifier,
  `personal-${string}`
>;
