export class ServiceOptionConfigJSON {
  // name in service-product table
  // to identify correct service-product
  // to select the correct human-readable titles /
  // descriptions in user interfaces
  baseProductName: string;
  identifier: string;
  max?: number;
}

export class ServiceCustomProductConfigJSON {
  serviceOptions: ServiceOptionConfigJSON[]; // [{baseProductName: <scope1+2 one>, max: 1}, {baseProductName: <scope3Cat one>, max: null}]
}

export type ServiceOptionConfigWithPrice = ServiceOptionConfigJSON & {
  price: number;
};

export type ServiceCustomProductConfigWithPrices = {
  options: ServiceOptionConfigWithPrice[];
};

export type ServiceProductConfigJSON = ServiceCustomProductConfigJSON;
