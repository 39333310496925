import type {
  ScopeThreeCategoryEnum,
  VerifyFootprintProductIdentifier,
} from "@s2z-platform/constants";
import type {
  BASE_PRODUCT_IDENTIFIER_SCOPE_1_2,
  BASE_PRODUCT_IDENTIFIER_SCOPE_3_CAT,
} from "../products";

class VerifyScopeOneAndTwoPaymentFulfillmentJSON {
  identifier: typeof BASE_PRODUCT_IDENTIFIER_SCOPE_1_2;
  verified: boolean;
}

class VerifyScopeThreeCatPaymentFulfillmentJSON {
  identifier: typeof BASE_PRODUCT_IDENTIFIER_SCOPE_3_CAT;
  verified: boolean;
  category: ScopeThreeCategoryEnum;
}

export class VerifyFootprintPaymentFulfillmentConfigJSON {
  productIdentifier: VerifyFootprintProductIdentifier;
  footprintUuid: string;
  items: (
    | VerifyScopeOneAndTwoPaymentFulfillmentJSON
    | VerifyScopeThreeCatPaymentFulfillmentJSON
  )[];
}

export type PaymentFulfillmentConfigsJSON =
  VerifyFootprintPaymentFulfillmentConfigJSON;
