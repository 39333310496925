import { mkenum } from "../../utils/mkenum";

export const ScopeOneCategoryEnum = mkenum({
  stationaryCombustion: "stationaryCombustion",
  mobileCombustion: "mobileCombustion",
  fugitiveEmissions: "fugitiveEmissions",
  processEmissions: "processEmissions",
});

export const ScopeOneCategoryEnumWithUnknown = mkenum({
  ...ScopeOneCategoryEnum,
  unknown: "unknown",
});

export type ScopeOneCategoryEnum =
  (typeof ScopeOneCategoryEnum)[keyof typeof ScopeOneCategoryEnum];

export type ScopeOneCategoryEnumWithUnknown =
  (typeof ScopeOneCategoryEnumWithUnknown)[keyof typeof ScopeOneCategoryEnumWithUnknown];

export const ScopeTwoCategoryEnum = mkenum({
  indirectElectricityEmissions: "indirectElectricityEmissions",
  companyElectricVehiclesChargedOffsite:
    "companyElectricVehiclesChargedOffsite",
  combinedHeatAndPower: "combinedHeatAndPower",
});

export const ScopeTwoCategoryEnumWithUnknown = mkenum({
  ...ScopeTwoCategoryEnum,
  unknown: "unknown",
});

export type ScopeTwoCategoryEnum =
  (typeof ScopeTwoCategoryEnum)[keyof typeof ScopeTwoCategoryEnum];

export type ScopeTwoCategoryEnumWithUnknown =
  (typeof ScopeTwoCategoryEnumWithUnknown)[keyof typeof ScopeTwoCategoryEnumWithUnknown];

export const ScopeThreeCategoryEnum = mkenum({
  purchasedGoodsAndServices: "purchasedGoodsAndServices",
  capitalGoods: "capitalGoods",
  fuelAndEnergyNotInS1OrS2: "fuelAndEnergyNotInS1OrS2",
  upstreamTransportationAndDistribution:
    "upstreamTransportationAndDistribution",
  wasteGeneratedInOperations: "wasteGeneratedInOperations",
  businessTravel: "businessTravel",
  employeeCommutingAndRemoteWorking: "employeeCommutingAndRemoteWorking",
  upstreamLeasedAssets: "upstreamLeasedAssets",
  downstreamTransportationAndDistribution:
    "downstreamTransportationAndDistribution",
  processingOfSoldProducts: "processingOfSoldProducts",
  useOfSoldProducts: "useOfSoldProducts",
  endOfLifeTreatmentOfSoldProducts: "endOfLifeTreatmentOfSoldProducts",
  downstreamLeasedAssets: "downstreamLeasedAssets",
  franchises: "franchises",
  investments: "investments",
});

export const ScopeThreeCategoryEnumWithUnknown = mkenum({
  ...ScopeThreeCategoryEnum,
  unknown: "unknown",
});

export type ScopeThreeCategoryEnum =
  (typeof ScopeThreeCategoryEnum)[keyof typeof ScopeThreeCategoryEnum];

export type ScopeThreeCategoryEnumWithUnknown =
  (typeof ScopeThreeCategoryEnumWithUnknown)[keyof typeof ScopeThreeCategoryEnumWithUnknown];

export const AllScopeCategoriesEnum = {
  ...ScopeThreeCategoryEnum,
  ...ScopeTwoCategoryEnum,
  ...ScopeOneCategoryEnum,
};

export const AllScopeCategoriesEnumWithUnknown = {
  ...ScopeThreeCategoryEnumWithUnknown,
  ...ScopeTwoCategoryEnumWithUnknown,
  ...ScopeOneCategoryEnumWithUnknown,
};

export type AllScopeCategoriesEnum =
  (typeof AllScopeCategoriesEnum)[keyof typeof AllScopeCategoriesEnum];

export type AllScopeCategoriesEnumWithUnknown =
  (typeof AllScopeCategoriesEnumWithUnknown)[keyof typeof AllScopeCategoriesEnumWithUnknown];
