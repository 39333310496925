import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import {
  AuthUser,
  getCurrentUser,
  signInWithRedirect,
  signOut,
} from "aws-amplify/auth";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { ADMIN_COGNITO_PROVIDER_NAME } from "@s2z-platform/constants";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { useAppDispatch } from "../../store/hooks";
import { enqueueErrorMessage } from "../../store/slices/appSlice";
import { logout as authLogout, saveTokens } from "../../store/slices/authSlice";

export type UseOAuthArgs = {
  redirectSignIn: string;
  redirectSignOut: string;
};

const useOAuth = ({ redirectSignIn, redirectSignOut }: UseOAuthArgs) => {
  const intl = useIntl();
  const [user, setUser] = useState<AuthUser | null>(null);

  const dispatch = useAppDispatch();

  const handleSignInFailure = ({ data }: any) => {
    let msg;
    // check if the login failed du to trigger validation
    if (
      /^(PreAuthentication|PreSignUp)/.test(data.message) &&
      /status\+code\+400/.test(data.message)
    ) {
      msg = intl.formatMessage({ id: "login.error.inexistant_account" });
    } else {
      msg = intl.formatMessage({ id: "login.error.login_issue" });
    }
    dispatch(enqueueErrorMessage(msg));
  };

  useEffect(() => {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: process.env
            .REACT_APP_COGNITO_ADMIN_USER_POOL_ID as string,
          userPoolClientId: process.env
            .REACT_APP_COGNITO_ADMIN_USER_POOL_WEB_CLIENT_ID as string,
          loginWith: {
            oauth: {
              domain: process.env
                .REACT_APP_COGNITO_ADMIN_OAUTH_DOMAIN as string,
              scopes: [
                "phone",
                "email",
                "profile",
                "openid",
                "aws.cognito.signin.user.admin",
              ],
              redirectSignIn: [redirectSignIn],
              redirectSignOut: [redirectSignOut],
              responseType: "token",
              providers: [{ custom: ADMIN_COGNITO_PROVIDER_NAME }],
            },
          },
        },
      },
    });

    const unsubscribe = Hub.listen("auth", async ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          if (!isEqual(user, payload.data)) {
            setUser(payload.data);

            const tokens =
              await cognitoUserPoolsTokenProvider.authTokenStore.loadTokens();

            const accessToken = tokens?.accessToken?.toString();
            const idToken = tokens?.idToken?.toString();
            const refreshToken = tokens?.idToken?.toString();

            if (accessToken && idToken && refreshToken) {
              dispatch(
                saveTokens({
                  accessToken,
                  idToken,
                  refreshToken,
                })
              );
            } else {
              // eslint-disable-next-line no-console
              console.error(
                `Failed to retrieve one or more tokens: '${[
                  accessToken,
                  idToken,
                  refreshToken,
                ].join("', '")}'`
              );
            }
          }
          break;
        case "signedOut":
        case "tokenRefresh_failure":
          setUser(null);
          dispatch(authLogout());
          break;
        case "customOAuthState":
          // eslint-disable-next-line no-console
          console.info("Custom auth state:", payload.data);
          break;
        case "signInWithRedirect_failure":
          // eslint-disable-next-line no-console
          console.info("Sign in failure", {
            event: payload.event,
            data: payload.data,
          });
          handleSignInFailure({ event: payload.event, data: payload.data });
          break;
        default:
      }
    });

    getCurrentUser()
      .then((currentUser) => {
        if (!isEqual(currentUser, user)) {
          setUser(currentUser);
        }
      })
      .catch(() => {});

    return unsubscribe;
  }, [redirectSignIn, redirectSignOut]);

  const logout = async () => {
    const res = await signOut();

    return res;
  };

  const login = async () => {
    const res = await signInWithRedirect({
      provider: { custom: ADMIN_COGNITO_PROVIDER_NAME },
    });

    return res;
  };

  return {
    user,
    login,
    logout,
  };
};

export default useOAuth;
