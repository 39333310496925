/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { fetchAuthSession } from "aws-amplify/auth";
import { LOGIN_PATH } from "../../constants/urls";
import { RootState } from "../store";

type User = {
  email: string;
  firstname: string;
  lastname: string;
  profileType: string;
};

type AuthState = {
  user?: User;
  isAuthenticated: boolean;
  idToken?: string;
  accessToken?: string;
  refreshToken?: string;
  processing?: boolean;
};

export const getMe = createAsyncThunk(
  "auth/getMe",
  async (jwtToken: string, { dispatch }): Promise<User | null> => {
    try {
      const session = await fetchAuthSession();
      if (!session && window.location.pathname !== LOGIN_PATH) {
        dispatch(logout());
        window.location.href = LOGIN_PATH;
        return null;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {
      if (window.location.pathname !== LOGIN_PATH) {
        dispatch(logout());
        window.location.href = LOGIN_PATH;
        return null;
      }
    }
    const apiUrl = process.env.REACT_APP_API_URL;
    const headers = new Headers();
    headers.set("authorization", `Bearer ${jwtToken}`);
    const res = await fetch(`${apiUrl}/api/admin/auth/me`, {
      headers,
    });
    if (res.status === 200) {
      return await res.json();
    } else if (res.status === 403) {
      dispatch(logout());
    }

    return null;
  }
);

export type TokenArgs = {
  refreshToken: string;
  accessToken: string;
  idToken: string;
};

/**
 * Default state object with initial values.
 */
const initialState: AuthState = {
  isAuthenticated: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    saveTokens: (state, action: PayloadAction<TokenArgs>) => {
      state.isAuthenticated = true;
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
      state.accessToken = action.payload.accessToken;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = undefined;
      state.accessToken = undefined;
      state.refreshToken = undefined;
      state.idToken = undefined;
      state.processing = false;
    },
    refreshToken: (state, action: PayloadAction<TokenArgs>) => {
      state.refreshToken = action.payload.refreshToken;
      state.accessToken = action.payload.accessToken;
    },
    setProcessing: (state, action: PayloadAction<boolean>) => {
      state.processing = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMe.pending, (state) => {
      state.processing = true;
    });
    builder.addCase(getMe.fulfilled, (state, action) => {
      state.processing = false;
      if (action.payload) {
        state.user = action.payload;
      } else {
        state.user = undefined;
      }
    });
    builder.addCase(getMe.rejected, (state) => {
      state.processing = false;
    });
  },
});

export const { logout, saveTokens, refreshToken, setProcessing } =
  authSlice.actions;

export const selectUserAndToken = createSelector(
  [
    (state: RootState) => state.auth.user,
    (state: RootState) => state.auth.accessToken,
    (state: RootState) => state.auth.isAuthenticated,
    (state: RootState) => state.auth.processing,
  ],
  (user, accessToken, isAuthenticated, processing) => ({
    user,
    accessToken,
    isAuthenticated,
    processing,
  })
);

export default authSlice.reducer;
