import {
  ConsolidationApproachEnum,
  EstimateStatusEnum,
  ScopeName,
  ScopeOneCategoryEnumWithUnknown,
  ScopeThreeCategoryEnumWithUnknown,
  ScopeTwoCategoryEnumWithUnknown,
} from "@s2z-platform/constants";

export const calculatedFootprintCopy: Record<
  | EstimateStatusEnum
  | ScopeName
  | `${ScopeName}Categories`
  | ConsolidationApproachEnum
  | ScopeOneCategoryEnumWithUnknown
  | ScopeTwoCategoryEnumWithUnknown
  | ScopeThreeCategoryEnumWithUnknown,
  string
> = {
  estimated: "Estimated",
  declared: "Declared",
  verifying: "Verifying",
  verified: "Verified",
  scopeOne: "Scope One",
  scopeTwo: "Scope Two",
  scopeThree: "Scope Three",
  scopeOneCategories: "Scope One Categories",
  scopeTwoCategories: "Scope Two Categeories",
  scopeThreeCategories: "Scope Three Categories",
  operationalControl: "Operational Control",
  financialControl: "Financial Control",
  equityShare: "Equity Share",
  stationaryCombustion:
    "Stationary Combustion: Combustion of Fuels in Company Owned or Controlled Buildings or Machinery",
  mobileCombustion: "Mobile Combustion: Company Owned or Controlled Vehicles",
  fugitiveEmissions:
    "Fugitive Emissions: Air Conditioning/ Refrigeration/Fire Suppression System",
  processEmissions:
    "Process Emissions: Released During Physical or Chemical Industrial Processing",
  indirectElectricityEmissions: "Indirect Emissions from Purchased Electricity",
  companyElectricVehiclesChargedOffsite:
    "Electric Vehicles: Company Owned or Controlled & Charged Offsite",
  combinedHeatAndPower: "Combined Heat & Power",
  purchasedGoodsAndServices: "Purchased Goods and Services",
  capitalGoods: "Capital Goods",
  fuelAndEnergyNotInS1OrS2: "Fuel & Energy not included in Scope 1 or 2",
  upstreamTransportationAndDistribution:
    "Upstream Transportation and Distribution",
  downstreamTransportationAndDistribution:
    "Downstream Transportation and Distribution",
  wasteGeneratedInOperations: "Waste Generated in Operations",
  businessTravel: "Business Travel",
  employeeCommutingAndRemoteWorking: "Employee Commuting/Remote Working",
  upstreamLeasedAssets: "Upstream Leased Assets",
  processingOfSoldProducts: "Processing of Sold Products",
  useOfSoldProducts: "Use of Sold Products",
  endOfLifeTreatmentOfSoldProducts: "End-of-Life Treatment of Sold Products",
  downstreamLeasedAssets: "Downstream Leased Assets",
  franchises: "Franchises",
  investments: "Investments",
  unknown: "I don't know",
};
